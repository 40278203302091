
<template>
  <div class="diagrambox">

    <div class="diagrambox-top">
      <div>
        <el-select v-model="optionid" placeholder="请选择" @change="changeequipment()">
          <el-option v-for="item in equipmentlist" :key="item.id" :label="item.menuName"
            :value="item.id" :popper-append-to-body="false">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="oderbox" v-if="this.optionid === 32 || this.optionid === 33">
      <div class="navigation">
        <div class="dornumbox">
          <p>设备总数</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon1.png" alt="">
            <p class="numpbox">{{odrlist.drinfoSum}}</p>
          </div>
        </div>
        <div class="dornumbox">
          <p>设备在线</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon2.png" alt="">
            <p class="numpbox">{{odrlist.drinfoRunSum}}</p>
          </div>
        </div>
        <div class="dornumbox">
          <p>设备离线</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon3.png" alt="">
            <p class="numpbox">{{odrlist.drinfoSum - odrlist.drinfoRunSum}}</p>
          </div>
        </div>
      </div>
      <div class="diagrambox-center">
        <TitleBox :titlename="'本月告警处理率'" />
        <div class="databox">
          <div class="echarbox">
            <EchartswaterPolo id="echartswaterPoloid" v-if="flagFX" class="echarbiongt"
              :waterPolonum="waterPolonum" />
          </div>
          <div class="box">
            <div class="numbox">
              <p>{{toDealWithlist.unhandled}} <span>个</span></p>
              <span class="spantext">待派单</span>
            </div>
            <div class="numbox">
              <p>{{toDealWithlist.issueWorkOrder}} <span>个</span></p>
              <span class="spantext">处理中</span>
            </div>
            <div class="numbox">
              <p>{{toDealWithlist.processed}} <span>个</span></p>
              <span class="spantext">已处理</span>
            </div>
          </div>
        </div>
      </div>
      <div class="diagrambox-footer">
        <TitleBox :titlename="'本月告警时段分布'" />
        <div class="echarbox">
          <Echartscolumnar id="Echartscolumnarid" v-if="flagFX" class="echarbiongt"
            :columnar="columnar" />
        </div>
      </div>
    </div>
    <div class="waterEnleft" v-if="this.optionid === 34">
      <div class="waterEnnavigation">
        <div class="dornumbox">
          <p>设备总数</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon1@2x.png" alt="">
            <p class="numpbox">{{this.collectionlist.drInfoSum}}</p>
          </div>
        </div>
        <div class="dornumbox">
          <p>设备在线</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon2@2x.png" alt="">
            <p class="numpbox">{{this.collectionlist.onlineNumber}}</p>
          </div>
        </div>
        <div class="dornumbox">
          <p>设备离线</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon3@2x.png" alt="">
            <p class="numpbox">{{this.collectionlist.drInfoSum-this.collectionlist.onlineNumber}}
            </p>
          </div>
        </div>
      </div>
      <div class="waterEn-center">
        <TitleBox :titlename="'水环境数据采集情况'" />
        <div class="box">
          <div class="numbox">
            <p>{{this.collectionlist.normalNumberOfData}} <span>个</span></p>
            <span class="spantext">采集正常数据</span>
          </div>
          <div class="numbox" @click="aquaticbtn()">
            <p>{{this.collectionlist.numberOfDataExceptions}} <span>个</span></p>
            <span class="spantext">采集异常数据</span>
          </div>
        </div>
      </div>
      <div class="waterEn-footer">
        <TitleBox :titlename="'水环境监测数据'" />
        <Switchbox :titlelist="['玻璃厂','江滩公园','石矶头','乌林水厂','鸭栏码头']" @swAreabtn="swAreabtn" />
        <div class="echarfooterbox">
          <boundary id="boundaryid" v-if="flagFX" class="echarboun"
            :WaterQualitylist="WaterQualitylist" />
          <div class="numbox">
            <p v-if="WaterQualitylist[0] >0 && WaterQualitylist[0]<= 4"
              style="color: RGBA(67, 169, 235, 0.9);">{{WaterQualitylist[0]}}</p>
            <p v-if="WaterQualitylist[0] >4 && WaterQualitylist[0]<= 32"
              style="color: RGBA(37, 205, 135, 0.9);">{{WaterQualitylist[0]}}</p>
            <p v-if="WaterQualitylist[0] >32" style="RGBA(255, 245, 88, 0.9);">
              {{WaterQualitylist[0]}}
            </p>
            <p v-if="WaterQualitylist[1] >0 && WaterQualitylist[1]<= 3"
              style="color: RGBA(67, 169, 235, 0.9);">{{WaterQualitylist[1]}}</p>
            <p v-if="WaterQualitylist[1] >3" style="color: RGBA(37, 205, 135, 0.9);">
              {{WaterQualitylist[1]}}</p>

            <p v-if="WaterQualitylist[2] >0 && WaterQualitylist[2]<= 25"
              style="color: RGBA(67, 169, 235, 0.9);">{{WaterQualitylist[2]}}</p>
            <p v-if="WaterQualitylist[2] >25 && WaterQualitylist[2]<= 60"
              style="color: RGBA(37, 205, 135, 0.9);">{{WaterQualitylist[2]}}</p>
            <p v-if="WaterQualitylist[2] >60" style="RGBA(255, 245, 88, 0.9);">
              {{WaterQualitylist[2]}}
            </p>
            <p v-if="WaterQualitylist[3] >0 && WaterQualitylist[3]<= 25"
              style="color: RGBA(67, 169, 235, 0.9);">{{WaterQualitylist[3]}}</p>
            <p v-if="WaterQualitylist[3] >25 && WaterQualitylist[3]<= 60"
              style="color: RGBA(37, 205, 135, 0.9);">{{WaterQualitylist[3]}}</p>
            <p v-if="WaterQualitylist[3] >60" style="RGBA(255, 245, 88, 0.9);">
              {{WaterQualitylist[3]}}
            </p>
            <p v-if="WaterQualitylist[4] >0 && WaterQualitylist[4]<= 6"
              style="color: RGBA(67, 169, 235, 0.9);">{{WaterQualitylist[4]}}</p>
            <p v-if="WaterQualitylist[4] >6 && WaterQualitylist[4]<= 9"
              style="color: RGBA(37, 205, 135, 0.9);">{{WaterQualitylist[4]}}</p>
            <p v-if="WaterQualitylist[4] >9" style="RGBA(255, 245, 88, 0.9);">
              {{WaterQualitylist[4]}}
            </p>
          </div>
        </div>

      </div>
    </div>
    <div class="waterEnleft" v-if=" this.optionid === 35">
      <div class="waterEnnavigation">
        <div class="dornumbox">
          <p>设备总数</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon1@2x.png" alt="">
            <p class="numpbox">{{this.biological.drinfoSum}}</p>
          </div>
        </div>
        <div class="dornumbox">
          <p>设备在线</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon2@2x.png" alt="">
            <p class="numpbox">{{this.biological.drinfoRunSum}}</p>
          </div>
        </div>
        <div class="dornumbox">
          <p>设备离线</p>
          <div class="iconbox">
            <img src="../../assets/homeimg/icon3@2x.png" alt="">
            <p class="numpbox">{{this.biological.drinfoSum-this.biological.drinfoRunSum}}
            </p>
          </div>
        </div>
      </div>
      <div class="waterEn-center">
        <TitleBox :titlename="'水下声学数据采集情况'" />
        <div class="box">
          <div class="numbox">
            <p>{{this.Finlessnum}} <span>个</span></p>
            <span class="spantext">采集江豚数据</span>
          </div>
          <div class="numbox">
            <p>{{this.othernum}} <span>个</span></p>
            <span class="spantext">采集其他数据</span>
          </div>
        </div>
      </div>
      <div class="waterEn-footer">
        <TitleBox :titlename="'水下声学采集情况统计'" />
        <!-- <div class="echarbox"> -->
        <Switchbox :titlelist="['玻璃厂','石矶头','江滩公园','乌林水厂','鸭栏码头']" @swAreabtn="swcoustic" />
        <acoustic id="boundaryid" v-if="flagFX" class="echaracoustic" :echartsobj="echartsobj" />
        <div class="radiobox">
          <el-radio-group v-model="radio" @change="Radiochange">
            <el-radio :label="1">近一周</el-radio>
            <el-radio :label="2">近一月</el-radio>
            <el-radio :label="3">近三月</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Switchbox from '../visualization/components/title/switch.vue'
import TitleBox from '../visualization/components/title/index.vue'
import EchartswaterPolo from '../visualization/components/echarts/echartswaterPolo.vue'
import Echartscolumnar from '../visualization/components/echarts/echartscolumnar.vue'
import boundary from '../visualization/components/echarts/boundary.vue'
import acoustic from '../visualization/components/echarts/acoustic.vue'
export default {
  props: ['equipmentlist', 'equipmentid'],
  components: {
    TitleBox,
    EchartswaterPolo,
    Echartscolumnar,
    boundary,
    acoustic,
    Switchbox

  },
  data () {
    return {
      optionid: 32,
      waterPolonum: null,
      flagFX: true,
      columnar: {
        xlist: [],
        ylist: []
      },
      collectionlist: null,
      toDealWithlist: {},
      WaterQualitylist: null,
      odrlist: [],
      biological: {},
      radio: 1,
      echartsobj: {},
      Finlessnum: 0,
      othernum: 0,
      WaterQualitydata: [],
      cousticdevId: '220506394700026'
    }
  },
  computed: {
    ...mapGetters('zsqy', ['WaterQuality', 'meteorological', 'hydrological'])
  },
  watch: {
    equipmentid: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          // console.log(newvalue, '下拉框')
          this.optionid = newvalue
          this.pageodrnum()
          // this.pagetoDealWith()
        })
      }
    },
    WaterQuality: {
      handler (val, old) {
        // console.log(val, '-=-=1232132132')
        // this.WaterQualitylist = [val.temp, val.ddo, val.ct, val.tur, val.ph]
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    swcoustic (index) {
      // console.log(index, 'index')
      if (index === 0) {
        this.cousticdevId = '220506394700040'
        this.pageacoustic(this.radio, this.cousticdevId)
      } else if (index === 1) {
        this.cousticdevId = '220506394700026'
        this.pageacoustic(this.radio, this.cousticdevId)
      } else if (index === 2) {
        this.cousticdevId = '220506394700028'
        this.pageacoustic(this.radio, this.cousticdevId)
      } else if (index === 3) {
        this.cousticdevId = '220506394700023'
        this.pageacoustic(this.radio, this.cousticdevId)
      } else if (index === 4) {
        this.cousticdevId = '211015292800032'
        this.pageacoustic(this.radio, this.cousticdevId)
      }
    },
    swAreabtn (index) {
      var obj = this.WaterQualitydata[index].data
      this.WaterQualitylist = [obj.temp, obj.ddo, obj.ct, obj.tur, obj.ph]
      console.log(this.WaterQualitylist, 'this.WaterQualitylist')
    },
    Radiochange () {
      this.pageacoustic(this.radio, this.cousticdevId)
    },
    aquaticbtn () {
      this.$emit('aquaticbtn', this.collectionlist.parameters)
    },
    changeequipment () {
      this.$emit('changeequipment', this.optionid)
    },
    pageodrnum () { // 设备在离线
      axios.get(this.$constant.zsqyUrl + '/zsqy/Drtypeinfo/11NatureReserves/findAllDrtypevo',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res90909090')
          res.data.data.forEach(ele => {
            if (this.optionid === 32) {
              if (ele.drtypeid === 4) {
                ele.drinfoRunSum = ele.drinfoSum
                this.odrlist = ele
              }
            } else if (this.optionid === 33) {
              if (ele.drtypeid === 7) {
                this.odrlist = ele
              }
            }
          })
        })
    },
    pagetoDealWith () { // 处理率
      axios.get(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findByProcessingQuantity',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.toDealWithlist = res.data.data
          // console.log(this.toDealWithlist, 'qwe')
          if (this.toDealWithlist.total === 0 || this.toDealWithlist.processed === 0) {
            this.waterPolonum = 0
          } else {
            this.waterPolonum = (this.toDealWithlist.processed / this.toDealWithlist.total).toFixed(2)
          }
          // else if (this.toDealWithlist.total !== 0 && this.toDealWithlist.processed === 0) {
          //   this.waterPolonum = 1
          // }
        })
    },
    pagedistribution () { // 时段分布
      axios.get(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findByAlarmPeriodDistribution',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          const totalEnergyData = res.data.data.map
          const totaledX = []
          const totaledY = []
          //  console.log(totalEnergyHou, "res111111");
          for (var key in totalEnergyData) {
            // this.mounthEnergy += parseInt(totalEnergyData[key]);// 总和
            var list = key.split(' ')

            totaledX.push(list[0])
            totaledY.push(totalEnergyData[key])
          }

          this.columnar.xlist = totaledX
          this.columnar.ylist = totaledY
          // console.log(this.columnar)
        })
    },
    // 查询水环境异常
    pagecollection () {
      axios.get(this.$constant.zsqyUrl + '/zsqy/drinfo/findWaterEnvironmentData',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'tableData1')
          this.collectionlist = res.data.data
        })
    },
    // 查询水下声学异常图表
    pageacoustic (type, devId) {
      axios.get(this.$constant.zsqyUrl + '/zsqy/waterincident/findByDate?' + 'type=' + type + '&devId=' + devId,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'this.tableData1')
          this.Finlessnum = 0
          this.echartsobj = res.data.data
          this.echartsobj.ylist.forEach(ele => {
            if (ele.name === '江豚声学识别') {
              ele.data.forEach(ele2 => {
                if (ele2) {
                  this.Finlessnum = this.Finlessnum + ele2
                }
              })
            } else if (ele.name === '其他') {
              ele.data.forEach(ele2 => {
                if (ele2) {
                  this.othernum = this.othernum + ele2
                }
              })
            }
          })
          // console.log(this.Finlessnum, this.othernum, 'num')
        })
    },
    pagebiological () {
      axios.get(this.$constant.zsqyUrl + '/zsqy/Drtypeinfo/11NatureReserves/findAllDrtypevo'
      )
        .then((res) => {
          // console.log(res, 'this.tableData1')
          // this.collectionlist = res.data.data
          res.data.data.forEach(ele => {
            if (ele.drtypeid === 19) {
              this.biological = ele
              // console.log(this.biological, 'this.biological')
            }
          })
        })
    },
    // 切换 水质 设备
    pageWaterQuality () {
      axios.get(this.$constant.zsqyUrl + '/zsqy/drinfo/waterEnvironment?' + 'drtypeid=1',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.WaterQualitydata = res.data.data
          console.log(this.WaterQualitydata, 'this.tableData1')
          var obj = this.WaterQualitydata[0].data
          this.WaterQualitylist = [obj.temp, obj.ddo, obj.ct, obj.tur, obj.ph]
        })
    },
    // swArea (index) {
    //   this.isAction = index
    //   // this.$emit('swArea', index)
    //   this.Dispose()
    //   if (index === 0) {
    //     this.op.GetEquipmentHelper().Load(1, (res) => { console.log(res) })
    //   } else if (index === 1) {
    //     this.op.GetEquipmentHelper().Load(2, (res) => { console.log(res) })
    //   }
    // },
    Dispose () {
      // console.log('qingkong')
      this.op.GetEquipmentHelper().Dispose()
    }
  },
  created () {
    this.pagecollection()
    this.pagetoDealWith()
    this.pagedistribution()
    this.pageodrnum()
    this.pagebiological()
    this.pageacoustic(1, '220506394700040')
    this.pageWaterQuality()
  }
}
</script>
<style lang="less" scoped>
.diagrambox {
  width: 100%;
  height: 100%;
  /deep/.el-input__inner {
    background-color: rgba(230, 252, 255, 0.3);
    border: none;
    color: #bfdafd;
    font-size: 12px;
    border-radius: 0px;
  }
  .echarbiongt {
    width: 100%;
    height: 100%;
  }
  .echarboun {
    width: 100%;
    height: 90%;
  }
  .echaracoustic {
    width: 100%;
    height: 80%;
  }
  .diagrambox-top {
    width: 100%;
    height: 5%;
    // background: #83b4ef;
    margin: 10px 0 1% 3%;
    overflow: hidden;
  }
  .oderbox {
    width: 100%;
    height: 94%;
    .navigation {
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      font-size: 14px;
      font-family: SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      .dornumbox {
        width: 30%;
        height: 60%;
        background: linear-gradient(
          90deg,
          rgba(67, 169, 235, 0.5) 0%,
          rgba(67, 169, 235, 0) 100%
        );
        border-radius: 4px;
        p {
          margin-left: 5px;
        }
        .iconbox {
          width: 100%;
          display: flex;
          justify-content: space-around;
          img {
            width: 20px;
            height: 20px;
            margin: 5px 0 0 0;
          }
        }
        .numpbox {
          font-size: 20px;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #9ddaff;
          // margin-left: 20px;
        }
      }
      .dornumbox:nth-child(2) {
        background: linear-gradient(
          90deg,
          rgba(37, 205, 135, 0.5) 0%,
          rgba(37, 205, 135, 0) 100%
        );
        .numpbox {
          color: #80ffca;
        }
      }
      .dornumbox:nth-child(3) {
        background: linear-gradient(
          90deg,
          rgba(255, 245, 88, 0.5) 0%,
          rgba(255, 245, 88, 0) 100%
        );
        .numpbox {
          color: #fff558;
        }
      }
    }
    .diagrambox-center {
      width: 100%;
      height: 35%;
      .databox {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: space-between;
        .echarbox {
          // background: #80ffca;
          width: 49%;
          height: 100%;
        }
        .box {
          // background: #80ffca;
          width: 49%;
          height: 100%;
          overflow: hidden;
          .numbox {
            width: 100%;
            height: 28%;
            margin-top: 3%;
            background: rgba(255, 10, 10, 0.3);
            border-left: 3px solid rgba(255, 10, 10, 1);
            font-size: 28px;
            font-family: SourceHanSansCN;
            font-weight: 500;
            color: #df4646;
            overflow: hidden;
            p {
              margin: 0 0 0 20%;
            }
            span {
              font-size: 16px;
              color: #fff;
            }
            .spantext {
              display: block;
              margin: 1% 20%;
            }
          }
          .numbox:nth-child(2) {
            background: rgba(255, 246, 98, 0.3);
            border-left: 3px solid rgba(255, 246, 98, 1);
            color: rgba(255, 246, 98, 1);
          }
          .numbox:nth-child(3) {
            background: rgba(72, 186, 255, 0.3);
            border-left: 3px solid rgba(72, 186, 255, 1);
            color: rgba(72, 186, 255, 1);
          }
        }
      }
    }
    .diagrambox-footer {
      width: 100%;
      height: 40%;
      margin-top: 20px;
      .echarbox {
        // background: #fff;
        width: 100%;
        height: 95%;
      }
    }
  }
  .waterEnleft {
    width: 100%;
    height: 80%;
    .waterEnnavigation {
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;
      font-family: SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      .dornumbox {
        width: 30%;
        height: 60%;
        background: linear-gradient(
          90deg,
          rgba(67, 169, 235, 0.5) 0%,
          rgba(67, 169, 235, 0) 100%
        );
        border-radius: 4px;
        p {
          margin-left: 5px;
        }
        .iconbox {
          width: 100%;
          display: flex;
          justify-content: space-around;
          img {
            width: 20px;
            height: 20px;
            margin: 5px 0 0 0;
          }
        }
        .numpbox {
          font-size: 20px;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #9ddaff;
          // margin-left: 20px;
        }
      }
      .dornumbox:nth-child(2) {
        background: linear-gradient(
          90deg,
          rgba(37, 205, 135, 0.5) 0%,
          rgba(37, 205, 135, 0) 100%
        );
        .numpbox {
          color: #80ffca;
        }
      }
      .dornumbox:nth-child(3) {
        background: linear-gradient(
          90deg,
          rgba(255, 245, 88, 0.5) 0%,
          rgba(255, 245, 88, 0) 100%
        );
        .numpbox {
          color: #fff558;
        }
      }
    }
    .waterEn-center {
      width: 100%;
      height: 20%;
      .box {
        // background: #80ffca;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        .numbox {
          width: 46%;
          height: 60%;
          margin-top: 3%;
          background: rgba(72, 186, 255, 0.3);
          border-left: 6px solid rgba(72, 186, 255, 1);
          color: rgba(72, 186, 255, 1);
          font-size: 28px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          overflow: hidden;
          p {
            margin: 0 0 0 20%;
          }
          span {
            font-size: 16px;
            color: #fff;
          }
          .spantext {
            display: block;
            margin: 1% 20%;
          }
        }
        .numbox:nth-child(2) {
          background: rgba(255, 246, 98, 0.3);
          border-left: 6px solid rgba(255, 246, 98, 1);
          color: rgba(255, 246, 98, 1);
        }
      }
    }
    .waterEn-footer {
      width: 100%;
      height: 80%;
      display: flex;
      flex-wrap: wrap;
      .echarfooterbox {
        width: 100%;
        height: 90%;
        .numbox {
          width: 91%;
          height: 10%;
          // background: #80ffca;
          margin-top: -5%;
          margin-left: 5%;
          display: flex;
          font-size: 14px;
          p {
            width: 20%;
            height: 100%;
            text-align: center;
          }
        }
      }

      .radiobox {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
</style>
