<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'columnar'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    columnar: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.columnar = newvalue
          this.initChart(this.id)
        })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: 'rgba(9, 24, 48, 0.5)',
          borderColor: 'rgba(75, 253, 238, 0.4)',
          textStyle: {
            color: '#CFE3FC'
          },
          borderWidth: 1
        },
        grid: {
          top: '15%',
          right: '15%',
          left: '15%',
          bottom: '12%'
        },
        xAxis: [{
          name: '天',
          type: 'category',
          data: this.columnar.xlist,
          axisLine: {
            lineStyle: {
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#e2e9ff',
            textStyle: {
              fontSize: 12
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          name: '次',
          axisLabel: {
            formatter: '{value}',
            color: '#e2e9ff'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#FFFFFF'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.12)'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: this.columnar.ylist,
          barWidth: '10%',
          itemStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,244,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(0,77,167,1)' // 100% 处的颜色
              }], false),
              shadowColor: 'rgba(0,160,221,1)',
              shadowBlur: 4
            }
          },
          label: {
            normal: {
              show: true,
              lineHeight: 10,
              formatter: '{c}',
              position: 'top',
              textStyle: {
                color: '#00D6F9',
                fontSize: 12
              }

            }
          }
        }]
      })
    }
  }
}
</script>
