<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'WaterQualitylist'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    WaterQualitylist: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.WaterQualitylist = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      const data1 = ['水温', '溶解氧', '亚硝酸盐', '氨氮', 'PH']
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        grid: {
          left: '5%',
          right: '4%',
          bottom: '10%',
          top: '10%'
        },
        legend: {
          // orient: 'vertical',
          top: '2%',
          width: 500,
          itemWidth: 12,
          itemHeight: 12,
          formatter: ['{a|{name}}'].join('\n'),
          itemGap: 50, // 头部左右距离【高位，正常，低位】
          textStyle: {
            fontSize: 12,
            color: '#fff',
            height: 8,
            rich: {
              a: {
                verticalAlign: 'bottom'
              }
            }
          },
          data: [{
            name: '高位',
            itemStyle: {
              color: 'RGBA(255, 245, 88, 1)'
            }
          }, {
            name: '正常',
            itemStyle: {
              color: '#40D039'
            }
          }, {
            name: '低位',
            itemStyle: {
              color: '#39A7D0'
            }
          }
          ]
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.45)'
            }
          },
          axisLabel: {
            // interval:0,
            fontSize: 12,
            color: '#fff'
          },
          // axisTick: {
          //   show: false,
          // },
          data: data1
        },
        yAxis: {
          type: 'value',
          // min: 0,
          // minInterval: 1,
          nameTextStyle: {
            fontSize: 12,
            color: '#BAE7FF',
            align: 'center'
          },
          axisLine: { show: false },
          axisTick: { show: false },
          splitArea: { show: false },
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.15)'
              // type: 'dashed', // dotted 虚线
            }
          },
          axisLabel: {
            show: false,
            fontSize: 12,
            color: '#6A93B9',
            fontFamily: 'Bebas'
          }
        },
        series: [
          {
            name: '低位',
            type: 'bar',
            barWidth: 40,
            stack: 'total',
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'RGBA(67, 169, 235, 0.9)' // 0% 处的颜色
                    },
                    {
                      offset: 0.3,
                      color: 'RGBA(67, 169, 235, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'RGBA(67, 169, 235, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: 'RGBA(67, 169, 235, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'RGBA(67, 169, 235, 0.9)' // 100% 处的颜色
                    }
                  ]
                }
              }
            },
            data: [4, 3, 25, 25, 6]
          },
          {
            name: '正常',
            type: 'bar',
            barWidth: 40,
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'RGBA(37, 205, 135, 0.9)' // 0% 处的颜色
                    },
                    {
                      offset: 0.3,
                      color: 'RGBA(37, 205, 135, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'RGBA(37, 205, 135, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: 'RGBA(37, 205, 135, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'RGBA(37, 205, 135, 0.9)' // 100% 处的颜色
                    }
                  ]
                }
              }
            },
            stack: 'total',
            // data: [30, 4, 5, 20, 5]
            data: [28, 97, 35, 35, 3]
          },
          {
            name: '高位',
            type: 'bar',
            barWidth: 40,
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'RGBA(255, 245, 88, 0.9)' // 0% 处的颜色
                    },
                    {
                      offset: 0.3,
                      color: 'RGBA(255, 245, 88, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'RGBA(255, 245, 88, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: 'RGBA(255, 245, 88, 0.3)' // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'RGBA(255, 245, 88, 0.9)' // 100% 处的颜色
                    }
                  ]
                }
              }
            },
            stack: 'total',
            // data: [30, 6, 9, 30, 8]
            data: [68, 0, 40, 40, 91]
          },
          { type: 'pictorialBar', symbol: 'rect', symbolPosition: 'end', barWidth: 40, color: '#fff', stack: 'total', z: 22, symbolSize: [40, 2], data: this.WaterQualitylist }
        ]
      })
    }
  }
}
</script>
