<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'echartsobj'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    echartsobj: {
      deep: true,
      // immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          // console.log(newvalue, 'newvalue')
          this.echartsobj = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      // console.log(this.echartsobj, '000')
      const namelist = []
      const datalist = []
      if ((JSON.stringify(this.echartsobj) !== '{}')) {
        // console.log('-=')
        this.echartsobj.ylist.forEach(element => {
          if (element.name === '江豚声学识别' || element.name === '其他') {
            namelist.push(element.name)
            datalist.push(element.data)
          }
        })
      }

      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(17,95,182,0.5)',
          textStyle: {
            color: '#fff'
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: namelist,
          align: 'left',
          right: 'center',
          bottom: 40,
          textStyle: {
            color: 'rgba(255,255,255,0.8)'
          },
          itemWidth: 13,
          itemHeight: 10,
          itemGap: 25
        },
        grid: {
          top: '5%',
          left: '8%',
          right: '8%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.echartsobj.xlist,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#063374',
              width: 1,
              type: 'solid'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'rgba(255,255,255,0.8)',
              fontSize: 14
            }
          }
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: 'rgba(255,255,255,0.8)'
          },
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: 'rgba(255,255,255,0.8)',
              fontSize: 14
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#063374',
              width: 1,
              type: 'solid'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#063374',
              type: ''
            }
          }
        }],
        series: [{
          name: namelist[0],
          type: 'bar',
          data: datalist[0],
          barWidth: 8, // 柱子宽度
          barGap: 1, // 柱子之间间距
          itemStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#00FFB4'
              }, {
                offset: 1,
                color: 'rgba(0, 255, 255, 0.1)'
              }]),
              opacity: 1
            }
          }
        }, {
          name: namelist[1],
          type: 'bar',
          data: datalist[1],
          barWidth: 8,
          barGap: 1,
          itemStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#0291FF'
              }, {
                offset: 1,
                color: 'rgba(12, 135, 230, 0.1)'
              }]),
              opacity: 1
            }
          }
        }]
      })
    }
  }
}
</script>
